import React from 'react';
import truncate from 'underscore.string/truncate';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Waiting from '../../common/containers/Waiting';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

class Folders extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.onFolderClick = this.onFolderClick.bind(this);
    this.onNewFolderClick = this.onNewFolderClick.bind(this);
    this.onFolderConfirm = this.onFolderConfirm.bind(this);
    this.onFolderCancel = this.onFolderCancel.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.onEditClickHandler = this.onEditClickHandler.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getInitialState() {
    return {
      folders: [],
      selectedFolder: this.props.selectedFolder || { id: 'all' },
      processing: false,
      showFolderForm: false,
      hasError: false,
      folderForm: {
        name: '',
        id: '',
      },
    };
  }

  componentDidMount() {
    this.fetchFolders();
  }

  componentDidUpdate(prevProps) {
    const prevFolder = prevProps.selectedFolder || { id: null };
    const currFolder = this.props.selectedFolder || { id: null };
    if (prevFolder.id !== currFolder.id) {
      this.setState({
        ...this.state,
        selectedFolder: currFolder,
      });
    }
  }

  fetchFolders() {
    this.setState({
      ...this.state,
      processing: true,
    });
    const url = `${Config.apiHost}folders/`;
    Ajax.get(url).done((folders) => {
      this.setState({
        ...this.state,
        folders,
        processing: false,
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      folderForm: {
        ...this.state.folderForm,
        [name]: value,
      },
    });
  }

  onFolderClick(folder) {
    this.setState({
      ...this.state,
      selectedFolder: folder,
    });
    this.props.onFolderClick(folder);
  }

  onNewFolderClick() {
    this.setState({
      ...this.state,
      showFolderForm: true,
    });
  }

  onFolderConfirm(evt) {
    this.setState({
      ...this.state,
      processing: true,
    });

    let xhr, url;
    const data = { name: this.state.folderForm.name };

    if (this.state.folderForm.id) {
      url = `${Config.apiHost}folders/${this.state.folderForm.id}/`;
      xhr = Ajax.put(url, data).done((response) => {
        this.setState(this.getInitialState(), this.fetchFolders);
      });
    } else {
      url = `${Config.apiHost}folders/`;
      xhr = Ajax.post(url, data).done((response) => {
        this.setState(this.getInitialState(), this.fetchFolders);
      });
    }

    xhr.fail((response) => {
      const { i18n } = this.props;
      const respJSON = response.responseJSON;
      this.setState({
        ...this.state,
        processing: false,
        hasError: true,
        errorMessage:
          respJSON && respJSON.name
            ? respJSON.name.join(' ')
            : i18n.t('Error inesperado'),
      });
    });

    evt.preventDefault();
  }

  onFolderCancel() {
    this.setState({
      ...this.state,
      showFolderForm: false,
      hasError: false,
      folderForm: {
        name: '',
        id: '',
      },
    });
  }

  onEditClickHandler(folder, e) {
    e.stopPropagation();
    this.setState({
      ...this.state,
      showFolderForm: true,
      folderForm: {
        name: folder.name,
        id: folder.id,
      },
    });
  }

  onDeleteClickHandler(folder, e) {
    e.stopPropagation();
    const { i18n } = this.props;
    const msg = `${i18n.t('Confirma a exclusão do marcador')} "${
      folder.name
    }"?`;
    const confirmed = confirm(msg);
    if (confirmed) {
      const url = `${Config.apiHost}folders/${folder.id}/`;
      this.setState({
        ...this.state,
        processing: true,
      });
      Ajax.delete_(url).done((response) => {
        this.fetchFolders();
        if (this.props.onFolderRemoved) {
          this.props.onFolderRemoved(folder);
        }
      });
    }
  }

  renderFolders() {
    const { i18n } = this.props;

    const allActive =
      this.state.selectedFolder && this.state.selectedFolder.id === 'all';

    const allItem = (
      <li
        key="all"
        onClick={() => this.onFolderClick({ id: 'all' })}
        className={`${allActive ? 'active' : ''}`}>
        <a role="button">{i18n.t('Todos')}</a>
      </li>
    );

    const otherItems = this.state.folders.map((folder) => {
      const active =
        this.state.selectedFolder && this.state.selectedFolder.id === folder.id;
      return (
        <li
          onMouseOver={() => $(`#folder-${folder.id}`).show()}
          onMouseOut={() => $(`#folder-${folder.id}`).hide()}
          key={folder.id}
          className={`${active ? 'active' : ''}`}>
          <a
            role="button"
            title={folder.name}
            onClick={() => this.onFolderClick(folder)}>
            <span>
              {truncate(folder.name, 20)}
              &nbsp;
            </span>

            <span className={`label label-${active ? 'primary' : 'default'}`}>
              {folder.reference_count}
            </span>

            <span
              id={`folder-${folder.id}`}
              style={{ display: 'none' }}
              className="pull-right">
              <span
                role="button"
                style={{ marginRight: '10px' }}
                onClick={(e) => this.onEditClickHandler(folder, e)}>
                <span className="mdi mdi-edit" />
              </span>
              <span
                role="button"
                style={{ marginRight: '10px' }}
                onClick={(e) => this.onDeleteClickHandler(folder, e)}>
                <span className="mdi mdi-delete" />
              </span>
            </span>
          </a>
        </li>
      );
    });

    return [allItem, ...otherItems];
  }

  renderForm() {
    const { i18n } = this.props;
    if (this.state.showFolderForm) {
      return (
        <form onSubmit={this.onFolderConfirm}>
          <div
            className={`form-group ${this.state.hasError ? 'has-error' : ''}`}>
            <input
              type="text"
              name="name"
              required={true}
              autoFocus={true}
              className="form-control"
              placeholder={`${i18n.t('Nome')} + ENTER`}
              onChange={this.handleInputChange}
              onBlur={this.onFolderCancel}
              autoComplete="off"
              value={this.state.folderForm.name}
            />
            {this.state.hasError && (
              <span className="help-block">{this.state.errorMessage}</span>
            )}
          </div>
        </form>
      );
    } else {
      return (
        <a
          role="button"
          title={i18n.t('Adicionar marcador')}
          className="btn btn-default btn-block"
          onClick={this.onNewFolderClick}>
          <i className="mdi mdi-plus-circle" />
          &nbsp;
          {i18n.t('Novo marcador')}
        </a>
      );
    }
  }

  render() {
    const { i18n } = this.props;
    return (
      <Waiting isProcessing={this.state.processing}>
        <ul className="nav">
          <span className="title">{i18n.t('Marcadores')}</span>
          {this.renderFolders()}
        </ul>
        <div className="aside-compose">{this.renderForm()}</div>
      </Waiting>
    );
  }
}

Folders.propTypes = {
  /**
   * A function receiving the folder object that was clicked on. If no folder
   * is passed, it means that the "All" folder was clicked.
   */
  onFolderClick: PropTypes.func,
  /**
   * A function receiving the folder object that was removed.
   */
  onFolderRemoved: PropTypes.func,
  /**
   * The folder initially selected.
   */
  selectedFolder: PropTypes.object,
};

export default withNamespaces()(Folders);
