import React from 'react';
import PrivatePage from '../common/containers/PrivatePage';
import Content from '../common/containers/Content';
import Folders from './components/Folders';
import ReferenceList from './components/ReferenceList';
import ErrorBoundary from '../common/containers/ErrorBoundary';
import { withNamespaces } from 'react-i18next';

class ReferenceListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeKey: null,
      folderId: null,
      selectedFolder: null,
      removedFolder: null,
    };
    this.onReferencesChangedHandler = this.onReferencesChangedHandler.bind(this);
    this.onFolderClick = this.onFolderClick.bind(this);
    this.onFolderRemoved = this.onFolderRemoved.bind(this);
    this.onSelectFolder = this.onSelectFolder.bind(this);
    this.selectFolderInReferenceList = null;
  }

  onReferencesChangedHandler() {
    this.setState({
      ...this.state,
      // A new key forces the component to remount.
      timeKey: new Date().getMilliseconds(),
    });
  }

  onFolderClick(folder) {
    this.setState((state) => ({
      ...state,
      selectedFolder: folder,
    }));
    this.selectFolderInReferenceList(folder);
  }

  onSelectFolder(folder) {
    this.setState((state) => ({
      ...state,
      selectedFolder: folder,
    }));
  }

  onFolderRemoved(folder) {
    this.setState((state) => ({
      ...state,
      removedFolder: folder,
    }));
  }

  renderAside() {
    return (
      <div className="aside-content">
        <div className="aside-nav">
          <Folders
            key={this.state.timeKey}
            onFolderClick={this.onFolderClick}
            onFolderRemoved={this.onFolderRemoved}
            selectedFolder={this.state.selectedFolder}
          />
        </div>
      </div>
    );
  }

  render() {
    const { i18n } = this.props;
    return (
      <PrivatePage title={i18n.t('Minhas referências')} hasAside={true}>
        <Content aside={this.renderAside()}>
          <ErrorBoundary>
            <ReferenceList
              selectFolderFuncRef={(f) => (this.selectFolderInReferenceList = f)}
              onReferencesChange={this.onReferencesChangedHandler}
              removedFolder={this.state.removedFolder}
              onSelectFolder={this.onSelectFolder}
            />
          </ErrorBoundary>
        </Content>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(ReferenceListPage);
